import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"

import Layout from "../components/layout"
import BlogCard from "../components/blogListCard"
import Button from "../components/button"

import styled from "@emotion/styled"

const breakpoints = [600, 700, 1000]

const mq = breakpoints.map(
  bp => `@media (min-width: ${bp}px)`
)

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ListWrapper = styled.div`
  width: 100%;
  ${mq[2]}{
    width: 80%;
    margin: 1rem auto;
  }
`

export default class BlogList extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()
    const blogURL = "/blog/"

    const siteTitle = " | Groove Café"
    const name = "blog".concat(siteTitle)

    return (
      <Layout>
        <SEO title={name} />
        {posts.map(({ node }) => {
          const { path, title, date, author, featuredImage } = node.frontmatter
          return (
            <ListWrapper>
              <BlogCard
                path={path}
                title={title}
                date={date}
                author={author}
                headerImage={featuredImage.childImageSharp.fluid}
                excerpt={node.excerpt}
              />
            </ListWrapper>
          )
        })}
        <ButtonWrapper>
          {!isFirst && (
            <Button
              link={blogURL.concat(prevPage)}
              text="← newer posts"
            />
          )}
          {!isLast && (
            <Button
              link={blogURL.concat(nextPage)}
              text="older posts →"
            />
          )}
        </ButtonWrapper>
      </Layout>
    )
  }
}

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { frontmatter: { postType: { eq: "blog" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(format: PLAIN, pruneLength: 500)
          frontmatter {
            date
            author
            title
            path
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
