import React from "react"
import { Link } from "gatsby"

import Img from "gatsby-image"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import colors from "../components/colors"

const breakpoints = [600, 700, 1000]

const mq = breakpoints.map(
  bp => `@media (min-width: ${bp}px)`
)

const Wrapper = styled.div`
  display: flex;
  position: sticky;
  padding: 1em;
`


const Box = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: grey;
  background-color: ${colors.lightyellow};
  box-shadow: -20px 20px ${colors.navy};
  max-width: 100%;
  position: sticky;
  z-index: 0;
`

const headerImage = css`
  width: 100%;
  margin-top: 1rem;
`

const UnderImage = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
  ${[mq[0]]} {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  ${mq[2]} {
    padding-left: 4rem;
    padding-right: 4rem;
  }
`

const Title = styled.h1`
  padding-top: 2rem;
  margin: 0 0;
`

const AuthorDate = styled.h6`
  line-height: 1.5;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin: 0 0;
  font-family: Noto Sans;
`

const Text = styled.div`
  margin: 0 0;
`

const LinkStyle = css`
    text-decoration: none;
`

const BlogCard = props => (
      <Wrapper tabindex="0">
        <Box>
          <UnderImage>
            <Link to={props.path} css={LinkStyle}>
              <Title>{props.title}</Title>
            </Link>
            <Img css={headerImage} fluid={props.headerImage} />
            <AuthorDate>
              {props.date}
              <br />
              by {props.author}
            </AuthorDate>
            <Text>
              {props.excerpt}
              <br /><br />
              <Link to={props.path} css={LinkStyle}>
                <em>Read more...</em>
              </Link>
            </Text>
          </UnderImage>
        </Box>
      </Wrapper>
)

export default BlogCard
